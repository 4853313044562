import { WorkOrderSurveyQuestionOptionsPipe, WorkOrderSurveyQuestionPipe } from './work-order-survey-question.pipe';

import { AddressPipe } from './address.pipe';
import { ArrayValuePipe } from './array-value.pipe';
import { CastPipe } from './cast.pipe';
import { CommonModule } from '@angular/common';
import { CountConfirmedEmployeesForDigipointEventPipe } from './count-confirmed-employees-for-digipoint-event.pipe';
import { CurrencyPipe } from './currency.pipe';
import { DateRangePipe } from './date-range.pipe';
import { DiscountPricingPipe } from './discount-pricing.pipe';
import { DiscountRequirementsPipe } from './discount-requirements.pipe';
import { EmployeeClosestDistancePipe } from './employee-closest-distance.pipe';
import { EmployeeHasApprovedDigipointEventsPipe } from './employee-has-approved-digipoint-events.pipe';
import { EmployeeHasCompletedDigipointCertificationPipe } from './employee-has-completed-digipoint-certification.pipe';
import { EmployeeHasCompletedHaHCertificationPipe } from './employee-has-completed-hah-certification.pipe';
import { EmployeeIsBookableOnDatePipe } from './employee-is-bookable-on-date.pipe';
import { EmployeeNamePipe } from './employee-name.pipe';
import { EmployeeProfilePictureBackgroundUrlPipe } from './employee-profile-picture-background-url.pipe';
import { FilterPipe } from './filter.pipe';
import { FindSelectedEmployeeInDigipointEventsPipe } from './find-selected-employee-in-digipoint-events.pipe';
import { FindSelectedEmployeeInMultiSelectPipe } from './find-selected-employee-in-multi-select.pipe';
import { HoursAndMinutesPipe } from './hours-and-minutes.pipe';
import { HoursPipe } from './hours.pipe';
import { IsAfterPipe } from './is-after.pipe';
import { IsSameDatePipe } from './is-same-date.pipe';
import { IsSameYearPipe } from './is-same-year.pipe';
import { LocaleDatePipe } from './date.pipe';
import { LocaleDecimalPipe } from './number.pipe';
import { MetricScorePipe } from './metric-score.pipe';
import { MinutesPipe } from './minutes.pipe';
import { NgModule } from '@angular/core';
import { PastDatePipe } from './is-past-date.pipe';
import { PhonePipe } from './phone.pipe';
import { ReplacePipe } from './replace.pipe';
import { TimePipe } from './time.pipe';
import { UrlToLinkPipe } from './url-to-link.pipe';
import { WorkOrderHasValidContractPipe } from './workorder-has-valid-contract.pipe';

@NgModule({
	declarations: [
		TimePipe,
		AddressPipe,
		PhonePipe,
		CurrencyPipe,
		ArrayValuePipe,
		ReplacePipe,
		DateRangePipe,
		HoursAndMinutesPipe,
		MetricScorePipe,
		HoursPipe,
		MinutesPipe,
		UrlToLinkPipe,
		DiscountRequirementsPipe,
		DiscountPricingPipe,
		EmployeeNamePipe,
		LocaleDatePipe,
		LocaleDecimalPipe,
		CastPipe,
		WorkOrderSurveyQuestionPipe,
		WorkOrderSurveyQuestionOptionsPipe,
		EmployeeHasApprovedDigipointEventsPipe,
		FindSelectedEmployeeInDigipointEventsPipe,
		FindSelectedEmployeeInMultiSelectPipe,
		CountConfirmedEmployeesForDigipointEventPipe,
		EmployeeHasCompletedDigipointCertificationPipe,
		EmployeeIsBookableOnDatePipe,
		EmployeeProfilePictureBackgroundUrlPipe,
		EmployeeClosestDistancePipe,
		IsSameYearPipe,
		IsAfterPipe,
		WorkOrderHasValidContractPipe,
		IsSameDatePipe,
		EmployeeHasCompletedHaHCertificationPipe,
		PastDatePipe,
		FilterPipe,
	],
	imports: [CommonModule],
	exports: [
		TimePipe,
		AddressPipe,
		PhonePipe,
		CurrencyPipe,
		ArrayValuePipe,
		ReplacePipe,
		DateRangePipe,
		HoursAndMinutesPipe,
		MetricScorePipe,
		HoursPipe,
		MinutesPipe,
		UrlToLinkPipe,
		DiscountRequirementsPipe,
		DiscountPricingPipe,
		EmployeeNamePipe,
		LocaleDatePipe,
		LocaleDecimalPipe,
		CastPipe,
		WorkOrderSurveyQuestionPipe,
		WorkOrderSurveyQuestionOptionsPipe,
		EmployeeHasApprovedDigipointEventsPipe,
		FindSelectedEmployeeInDigipointEventsPipe,
		FindSelectedEmployeeInMultiSelectPipe,
		CountConfirmedEmployeesForDigipointEventPipe,
		EmployeeHasCompletedDigipointCertificationPipe,
		EmployeeIsBookableOnDatePipe,
		EmployeeProfilePictureBackgroundUrlPipe,
		EmployeeClosestDistancePipe,
		IsSameYearPipe,
		IsAfterPipe,
		WorkOrderHasValidContractPipe,
		IsSameDatePipe,
		EmployeeHasCompletedHaHCertificationPipe,
		PastDatePipe,
		FilterPipe,
	],
})
export class PipesModule {}
