import { IApiObject, IChangePasswordCredentials, ILoginCredentials } from '@shared-libs/interfaces';
import { Observable, firstValueFrom } from 'rxjs';

import { Api } from '@shared-providers/api.provider';
import { IUser } from '@shared/models/user.model';
import { Injectable } from '@angular/core';
import { LoadingService } from '@shared-services/loading.service';
import { Provider } from '@shared-providers/Provider';

export enum LoginEndpoints {
	login = '/login',
	isActive = '/login/active',
}

@Injectable({
	providedIn: 'root',
})
export class LoginProvider extends Provider<IUser> {
	protected readonly url: string = '/login';

	constructor(
		private readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(loadingService);
	}

	public login(_credentials: ILoginCredentials): Observable<IApiObject<IUser>> {
		return this.process(this.api.post<ILoginCredentials, IUser>(LoginEndpoints.login, _credentials));
	}

	public requestReset(_credentials: { username: string }): Observable<IApiObject<{ message: string }>> {
		return this.process(
			this.api.post<{ username: string }, { message: string }>(`${this.url}/reset`, _credentials)
		);
	}

	public reset(_credentials: { token: string; password: string }): Observable<IApiObject<{ message: string }>> {
		return this.process(
			this.api.post<{ token: string; password: string }, { message: string }>(
				`${this.url}/reset/confirm`,
				_credentials
			)
		);
	}

	public patchPassword(
		employeeId: string,
		_credentials: Pick<IChangePasswordCredentials, 'new'>
	): Observable<IApiObject> {
		return this.process(
			this.api.put(`/employees/${employeeId}/initialize/password`, { password: _credentials.new })
		);
	}

	public updatePassword(_credentials: IChangePasswordCredentials): Observable<IApiObject> {
		return this.process(this.api.post(`${this.url}/change`, _credentials));
	}

	public hasChangedPassword(): Observable<IApiObject<boolean>> {
		return this.process(this.api.get<boolean>(`${this.url}/change`));
	}

	public async hasLoggedInBefore(): Promise<boolean> {
		return (await firstValueFrom(this.process(this.api.get<boolean>(`${this.url}/has-logged-in`)))).data;
	}

	public async isActive(): Promise<boolean> {
		return (await firstValueFrom(this.process(this.api.get<boolean>(LoginEndpoints.isActive)))).data;
	}
}
