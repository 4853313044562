import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { LoginProvider } from '@shared-providers/beecloud/login.provider';
import { ModalController } from '@ionic/angular';
import { ReleaseNoteProvider } from '@shared-providers/beecloud/release-note.provider';
import { ReleaseNotesComponent } from '@shared-components/release-notes-component/release-notes.component';
import { UserManager } from '@shared-managers/user.manager';

/**
 * The app initialisation service that handles all logic that should be ran/initialized
 * on start of the application & when the user is authenticated
 *
 * Is initialized in the SharedComponent
 */
@Injectable({
	providedIn: 'root',
})
export class SharedAppInitialisationService {
	constructor(
		private readonly modalController: ModalController,
		private readonly releaseNoteProvider: ReleaseNoteProvider,
		private readonly loginProvider: LoginProvider,
		private readonly authenticationService: AuthenticationService,
		private readonly userManager: UserManager
	) {}

	/**
	 * A check whether the user is still active, based on the server not the client
	 * @returns whether or not the user is still active
	 */
	public async userIsActive(): Promise<boolean> {
		if (await this.authenticationService.isAuthenticated()) {
			const isActive = await this.loginProvider.isActive();
			if (isActive) {
				return true;
			}
			this.authenticationService.logout();
			return false;
		}
		return false;
	}

	/**
	 * A check whether the user, when consultant, has seen the latest
	 * release notes. If not open a model to show them.
	 * @returns Promise
	 */
	public async hasSeenLatestReleaseNotes(): Promise<void> {
		const hasLoggedInBefore = await this.loginProvider.hasLoggedInBefore();

		if (!hasLoggedInBefore) {
			return;
		}

		const result = await this.releaseNoteProvider.hasSeenLatest();

		if (!result.data.seen) {
			const modal = await this.modalController.create({
				component: ReleaseNotesComponent,
				canDismiss: false,
				cssClass: `auto-height ${this.userManager.isConsultant() ? 'mobile-only' : ''}`,
				componentProps: {
					notes: result.data.latest,
				},
			});
			await modal.present();
		} else {
			return;
		}
	}
}
