import { Observable, firstValueFrom } from 'rxjs';

import { Api } from '@shared-providers/api.provider';
import { IApiObject } from '@shared-libs/interfaces';
import { IReleaseNote } from '@shared/models/release-note.model';
import { Injectable } from '@angular/core';
import { LoadingService } from '@shared-services/loading.service';
import { Provider } from '@shared-providers/Provider';

@Injectable({
	providedIn: 'root',
})
export class ReleaseNoteProvider extends Provider<IReleaseNote> {
	protected readonly url: string = '/release-notes';

	constructor(
		private readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(loadingService);
	}

	public getLatest(): Promise<IApiObject<IReleaseNote>> {
		return firstValueFrom(
			this.process(this.api.get(`${this.url}/latest`), false) as Observable<IApiObject<IReleaseNote>>
		);
	}

	public hasSeenLatest(): Promise<IApiObject<{ seen: boolean; latest: IReleaseNote }>> {
		return firstValueFrom(this.process(this.api.get(`${this.url}/seen`), false) as Observable<IApiObject>);
	}

	public seenReleaseNote(id: string): Promise<IApiObject> {
		return firstValueFrom(this.process(this.api.post(`${this.url}/${id}`, {}), false) as Observable<IApiObject>);
	}
}
